<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('deviceGateway.supportProtocol')"
    width="500px"
    top="3vh">
    <div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="A2"></el-checkbox>
        <el-checkbox label="A4"></el-checkbox>
        <el-checkbox label="Ad"></el-checkbox>
        <el-checkbox label="Ae"></el-checkbox>
      </el-checkbox-group>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("elevatorNetApply.cancel")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$t("elevatorNetApply.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    props: {
      multiSelect: {
        type: Array,
      },
    },
    data() {
      return {
        checkList: [],
        dialogVisible: false,
        submitLoading: false,
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
      },
      handleSubmit() {
        let deviceIds = [];
        if (this.multiSelect && this.multiSelect.length > 0) {
          for (let i = 0; i < this.multiSelect.length; i++) {
            let item = this.multiSelect[i];
            deviceIds.push(item.id);
          }
          let params = {
            "deviceIds": deviceIds,
            "protocol": this.checkList.join(","),
          };
          this.$confirm(this.$t("deviceGateway.MakeSureProtocols"), this.$t("common.tips"), {type: "warning"}).then(() => {
            this.$http.save("deviceGateway/setSupportProtocol", params).then(() => {
              this.dialogVisible = false;
              this.submitLoading = false;
              this.$emit("save-success");
              this.$message.success(this.$t("common.tip.settingSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.settingError") + "," + error);
            });
          });
        } else {
          console.log(this.checkList);
          this.$message.error(this.$t("deviceGateway.selectSupportProtocol"));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
