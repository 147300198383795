<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('deviceGateway.editBlack')"
    width="500px"
    top="3vh">
    <div>
      <span style="margin-left: 100px">{{$t("deviceGateway.blacklist")}}</span> :
      <el-radio-group v-model="isBlack" style="margin-left: 20px">
        <el-radio :label="0">{{$t("deviceGateway.yes")}}</el-radio>
        <el-radio :label="1">{{$t("deviceGateway.no")}}</el-radio>
      </el-radio-group>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("elevatorNetApply.cancel")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$t("elevatorNetApply.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      multiSelect: {
        type: Array,
      },
    },
    data() {
      return {
        isBlack: 0,
        dialogVisible: false,
        submitLoading: false,
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
      },
      handleSubmit() {
        let deviceIds = [];
        if (this.multiSelect && this.multiSelect.length > 0) {
          for (let i = 0; i < this.multiSelect.length; i++) {
            let item = this.multiSelect[i];
            deviceIds.push(item.id);
          }
          if (this.isBlack === 0) {
            this.$confirm(this.$t("common.setBlack"), this.$t("common.tips"), {type: "warning"}).then(() => {
              this.$http.save("deviceGateway/setBlack", deviceIds).then(() => {
                this.dialogVisible = false;
                this.submitLoading = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.settingSuccess"));
              }).catch(error => {
                this.submitLoading = false;
                this.$message.error(this.$t("common.tip.settingError") + "," + error);
              });
            });
          } else {
            this.$confirm(this.$t("common.cancelBlack"), this.$t("common.tips"), {type: "warning"}).then(() => {
              this.$http.save("deviceGateway/cancelBlack", deviceIds).then(() => {
                this.dialogVisible = false;
                this.submitLoading = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.settingSuccess"));
              }).catch(error => {
                this.submitLoading = false;
                this.$message.error(this.$t("common.tip.settingError") + "," + error);
              });
            });
          }
        } else {
          this.$message.error(this.$t("deviceGateway.selectBlacklist"));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
