<template>
  <el-drawer
    :title="$t('deviceGateway.elevatorBind')"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    size="70%">
    <div style="margin-left: 20px;margin-right: 20px">
      <el-table
        border
        v-loading="loading"
        :data="tableData"
        class="dtu-list"
        @expand-change="expandSelect">
        <el-table-column type="expand" align="center" style="">
          <template slot-scope="scope">
            <device-patch :dtu-code="scope.row.dtuCode" :node-code="scope.row.nodeCode" :integration-key="scope.row.integrationKey"></device-patch>
          </template>
        </el-table-column>
        <el-table-column :label="$t('elevator.no')" align="center">
          <template slot-scope="scope">
            <router-link :to="'/elevator/'+ scope.row.id" target="_blank">{{scope.row.no}}</router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="regNo"
          :label="$t('elevator.registerCode')"
          align="center"></el-table-column>
        <el-table-column
          prop="name"
          :label="$t('elevator.name')"
          width="200px"
          align="center"></el-table-column>
        <el-table-column
          prop="dtuCode"
          :label="$t('elevator.dtuCode')"
          align="center"></el-table-column>
        <el-table-column
          prop="nodeCode"
          :label="$t('elevator.nodeCode')"
          align="center"
          width="80"></el-table-column>
        <el-table-column
          prop="useUnitName"
          :label="$t('elevator.useUnit')"
          align="center"></el-table-column>
        <el-table-column
          prop="createComName"
          :label="$t('elevator.manufactured')"
          align="center"></el-table-column>
        <el-table-column
          prop="maintComName"
          :label="$t('elevator.maintenanced')"
          align="center"></el-table-column>
        <el-table-column
          v-if="$i18n.isCn"
          :label="$t('elevator.accessPlatform')"
          width="100"
          align="center">
          <template v-slot="scope">
            <span v-for="(item, index) in scope.row.elevatorIntegrationVos" :key="index">
              <span>{{item.integrationKeyName + (index === scope.row.elevatorIntegrationVos.length - 1 ? "" : ",")}}</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-drawer>
</template>

<script>
  import DevicePatch from "@/views/deviceGateway/DevicePatch";

  export default {
    components: {DevicePatch},
    data() {
      return {
        drawer: false,
        loading: false,
        tableData: [],
        direction: "rtl",
      };
    },
    methods: {
      open(dtuCode) {
        this.drawer = true;
        this.loading = true;
        this.$http.get("deviceGateway/dtuNode/" + dtuCode).then(({data}) => {
          this.tableData = data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      },
      expandSelect(row, expandedRows) {
        // row.bufferData = [];
        // row.runtime = "1000";
        // row.runtimes = "2000";
      },
      handleClose(done) {
        done();
      },
    },
  };
</script>

<style lang="scss" scoped>
.dtu-list {
  ::v-deep .el-table__expanded-cell {
    background-color: #F1F5F8;
    padding: 15px 25px;

    &:hover {
      background-color: #F1F5F8 !important;
    }

    .el-divider__text {
      background-color: #F1F5F8;
    }
  }
}
</style>
