<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$t('deviceGateway.editDevice')"
    width="1500px"
    top="3vh"
    @close="$reset('form')">
    <el-form
      ref="form"
      :label-width="$l('elevatorNetApply.labelWidth','120px')"
      :model="model">
      <div class="vm-separate">
        <el-form-item :label="$t('elevator.joinStatus')">
          <el-switch
            v-model="model.inNetState"
            :active-text="$t('elevator.yes')"
            :inactive-text="$t('elevator.No')"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('elevator.groupControl')">
          <el-switch
            v-model="model.isParallel"
            :active-text="$t('elevator.yes')"
            :inactive-text="$t('elevator.No')"></el-switch>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.deviceModel')" prop="deviceModel">
          <el-input v-model.trim="model.deviceModel" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.signalType')" prop="signalType">
          <el-input v-model.trim="model.signalType" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.dtuCode')" prop="dtuCode">
          <el-input v-model.trim="model.dtuCode" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.bindState')" prop="bindState">
          <el-input v-model.trim="model.bindState" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.softVer')" prop="softVer">
          <el-input v-model.trim="model.softVer" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.hardVer')" prop="hardVer">
          <el-input v-model.trim="model.hardVer" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.simCode')" prop="simCode">
          <el-input v-model.trim="model.simCode" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.simICCID')" prop="simICCID">
          <el-input v-model.trim="model.simICCID" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.signalStrength')" prop="signalStrength">
          <el-input v-model.trim="model.signalStrength" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.temperature')" prop="temperature">
          <el-input v-model.trim="model.temperature" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('deviceGateway.ip')" prop="ip">
          <el-input v-model.trim="model.ip" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('device.port')" prop="port">
          <el-input v-model.trim="model.port" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('device.APNName')" prop="apnName">
          <el-input v-model.trim="model.apnName" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.linkStatus')" prop="connectionStatus">
          <el-input v-model.trim="model.connectionStatus" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('device.userName')" prop="appUserName">
          <el-input v-model.trim="model.appUserName" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('device.password')" prop="appPassword">
          <el-input v-model.trim="model.appPassword" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("elevatorNetApply.cancel")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$t("elevatorNetApply.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        model: {
          deviceModel: "",
          signalType: "",
          dtuCode: "",
          bindState: "",
          softVer: "",
          hardVer: "",
          simCode: "",
          simICCID: "",
          signalStrength: "",
          temperature: "",
          electricityQuantity: "",
          ip: "",
          port: "",
          apnName: "",
          appUserName: "",
          appPassword: "",
          connectionStatus: "",
          isParallel: false,
          inNetState: false,
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        if (this.model.id) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("deviceGateway/" + this.model.id)
          .then(({data}) => {
            this.$assign(this.model, data);
          });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("deviceGateway", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
