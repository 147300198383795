<template>
  <el-drawer
    :title="$t('deviceGateway.GatewayPushStatistics')"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    size="670px">
    <div style="margin-left: 20px;margin-right: 20px">
      <el-alert
        :description="$t('deviceGateway.processing')"
        type="warning"
        show-icon
        :closable="false">
      </el-alert>
      <div style="padding-top: 16px;float: left;font-weight: bold;font-size: 15px">
        {{$t("userLoginLog.startTime")}}:{{pushCount.startTime}}&nbsp;&nbsp;
        {{$t("userLoginLog.endTime")}}:{{pushCount.endTime}}
      </div>
      <div style="padding: 10px 0;float: right">
        <el-button
          type="primary"
          @click="handleRefresh">{{$t("deviceGateway.refresh")}}
        </el-button>
        <el-button
          type="primary"
          style="margin-left: 5px;"
          @click="handleClear">
          {{$t("deviceGateway.clear")}}
        </el-button>
      </div>
      <el-table
        :data="pushCount.counts"
        v-loading="loading"
        :max-height="myMaxHeight"
        border
        style="width: 100%">
        <el-table-column :label="$t('deviceGateway.dtuNode')">
          <template slot-scope="scope">
            {{scope.row.dtuCode}}:{{scope.row.nodeCode}}
          </template>
        </el-table-column>
        <el-table-column
          prop="count"
          sortable
          :label="$t('deviceGateway.pushCount')">
        </el-table-column>
        <el-table-column
          prop="lastTime"
          sortable
          :label="$t('deviceGateway.lastTime1')">
        </el-table-column>
      </el-table>
    </div>
  </el-drawer>
</template>

<script>
  export default {
    data() {
      return {
        drawer: false,
        dialogVisible: false,
        loading: false,
        direction: "rtl",
        myMaxHeight: 0,
        pushCount: {
          startTime: "",
          endTime: "",
          counts: [],
        },
      };
    },
    mounted() {
      this.changeMaxHeight();
      window.onresize = () => {
        this.changeMaxHeight();
      };
    },
    methods: {
      open() {
        this.clearData();
        this.drawer = true;
        this.dialogVisible = true;
        this.getData();
      },
      clearData(){
        this.pushCount.startTime = "";
        this.pushCount.endTime = "";
        this.pushCount.counts = [];
      },
      getData() {
        this.loading = true;
        this.$http.get("deviceGateway/dtuPushCount").then(({data}) => {
          this.pushCount = data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      changeMaxHeight() {
        if (this.maxHeight) {
          this.myMaxHeight = this.maxHeight;
        } else {
          let clientHeight = document.body.clientHeight;
          this.myMaxHeight = clientHeight - 230;
        }
      },
      handleRefresh() {
        this.getData();
      },
      handleClear() {
        this.$http.get("deviceGateway/dtuPushCount/clear").then(({data}) => {
          this.clearData();
        });
      },
      handleClose(done) {
        done();
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
